import React, { useEffect, useRef, useContext } from 'react';

import { Grid, Container, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AllIndustryCard } from './AllIndustryCard';
import { Testimonials } from '../../Testimonials';
import IndustrySearchContext from '../../../context/IndustrySearchContext';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles((theme) => ({
	noIndustryTitle: {
		color: '#fff',
		fontWeight: 700,
		fontSize: '2rem',
		marginBottom: '0.5rem',
		lineHeight: '1.2',
	},
	noIndustrySubTitle: {
		color: '#fff',
		fontWeight: 500,
		fontSize: '1.1rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '1rem',
		},
	},
	noIndustryCTA: {
		background: theme.lightBlue,
		// opacity: 1,
		transition: 'all 0.35s ease !important',
		// '&:hover': {
		// 	opacity: '0.85 !important',
		// },
	},
	cards: {
		marginTop: '-13rem',
		//paddingBottom: '5.5em',
		[theme.breakpoints.down('lg')]: {
			marginTop: '-15rem',
		},
		[theme.breakpoints.down('md')]: {
			marginTop: '-12rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-10rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '-6rem',
		},
	},
}));

export const AllIndustryBody = ({
	allIndustries,
	industriesData,
	setFormModalOpen,
}) => {
	const classes = useStyles();
	const formRef = useRef(null);

	const { setDefault, filteredIndustries } = useContext(IndustrySearchContext);

	useEffect(() => {
		setDefault(industriesData);
	}, []);

	const {
		noIndustryTitle,
		noIndustrySubTitle,
		noIndustryctaText,
		testimonialHeader,
		testimonial,
	} = allIndustries;
	//Think I want to clean and refactor this into smaller components for readability.

	return (
		<>
			<Container fixed>
				<Grid container direction='row' spacing={4} className={classes.cards}>
					{/* {!filteredIndustries.length && (
						<Grid item xs={12}>
							<Typography
								variant='h6'
								style={{ textAlign: 'center', marginTop: '12rem' }}>
								No results returned
							</Typography>
						</Grid>
					)} */}
					{!!filteredIndustries.length
						? filteredIndustries.map((industry, index) => (
								<AllIndustryCard
									industry={industry.node}
									content={industry.searchParams}
									key={index}
								/>
						  ))
						: null}
				</Grid>
			</Container>
			<div
				style={{
					//background: 'rgb(5,82,145)',
					background:
						'linear-gradient(45deg, rgba(5,82,145,1) 20%, rgba(0,45,92,1) 80%)',
					padding: '3.5em 0',
					marginTop: !!filteredIndustries.length ? '10rem' : '14rem',
				}}>
				<Container>
					<Grid
						container
						direction='row'
						justifyContent='flex-start'
						alignItems='center'>
						<Grid item xs={12} md={9} lg={8}>
							<Typography variant='h2' className={classes.noIndustryTitle}>
								{noIndustryTitle}
							</Typography>
							<Typography variant='h4' className={classes.noIndustrySubTitle}>
								{noIndustrySubTitle}
							</Typography>
						</Grid>
						<Grid item xs={12} md={3} lg={2}>
							<Button
								variant='contained'
								size='medium'
								color='primary'
								onClick={() => setFormModalOpen(true)}
								className={classes.noIndustryCTA}>
								{noIndustryctaText}
							</Button>
						</Grid>
					</Grid>
				</Container>
			</div>
			<div
				style={{
					background: 'transparent',
					padding: '2rem 0',
				}}>
				<Testimonials
					header={testimonialHeader}
					testimonials={testimonial}
					formRef={formRef}
				/>
			</div>
		</>
	);
};
